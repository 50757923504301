import React from 'react';

import Header from '../Header'
import AdminSidebar from './AdminSidebar'
import Footer from '../Footer'
import { ContestProvider } from '../../../ContestContext';

const AdminBase = props => (
    <ContestProvider>
        <div className="wrapper">
            <Header />

            <AdminSidebar />

            <section className="section-container">
                { props.children }
            </section>

            <Footer />
        </div>
    </ContestProvider>
)

export default AdminBase;
