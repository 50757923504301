import React from 'react';

import Header from '../Header'
import Footer from '../Footer'
import { ContestProvider } from '../../../ContestContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CoordinatorSidebar from './CoordinatorSidebar';

const CoordinatorBase = props => (
    <ContestProvider>
        <ToastContainer />
        <div className="wrapper">
            <Header />

            <CoordinatorSidebar />

            <section className="section-container">
                { props.children }
            </section>

            <Footer />
        </div>
    </ContestProvider>
)

export default CoordinatorBase;
