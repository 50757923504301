
const prod = {
    REST_URL: process.env.REACT_APP_URL+'/api',
    WS_URL: process.env.REACT_APP_URL+'/ws'
};

const dev = {
    REST_URL: 'http://localhost:8080/api',
    WS_URL: 'http://localhost:8080/ws'
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
