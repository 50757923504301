import React, {useState} from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Link, withRouter, useLocation } from 'react-router-dom';
import { Badge } from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../store/actions/actions';

import Menu from './Menu.js';
import SidebarLogo from '../SidebarLogo';
import { useAuth } from '../../../AuthContext';
import ContestCountdown from '../ContestCountdown';
import { useContest } from '../../../ContestContext';
import { useRest } from '../../../RestContext';
import useAsync from '../../../useAsync';
import PageLoader from '../../Common/PageLoader';
import TooltipItem from '../../Common/TooltipItem';
import { useMonitoring } from '../../../MonitoringContext';
import DZIContestCountdown from '../DZIContestCountdown';

/** Component to display headings on sidebar */
const SidebarItemHeader = ({item}) => {
    const { t } = useTranslation();

    return (
    <li className="nav-heading">
        <span>{t(item.translate)}</span>
    </li>)
}

/** Normal items for the sidebar */
const SidebarItem = ({item, isActive}) => {
    const { t } = useTranslation();
    const post = useRest().post;
    const change = async () => {
        if (item.name === Menu.items.communication.name) {
            await post(`communication/seen`, new FormData());
        }
    }
    return (
        <li className={ isActive ? 'active' : '' }>
            <Link to={item.path} title={item.name} onClick={() => change()}>
                {item.label && !!item.label.value && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
                {item.icon && <em className={item.icon}></em>}
                <span>{t(item.translate)?t(item.translate):item.name}</span>
            </Link>
        </li>
    )
}

const UserSidebar = () => {
    const location = useLocation();
    const { t } = useTranslation();

    const user = useAuth().user;
    
    const unread = useContest().unreadAnnouncements + useContest().unreadQuestions;
    const startTime = useContest().startTime;
    const endTime = useContest().endTime;
    const contestStarted = useContest().contestStarted;
    const contestFinished = useContest().contestFinished;

    const cameraEnabled = useMonitoring().cameraEnabled;
    const startCamera = useMonitoring().startCamera;
    const screenEnabled = useMonitoring().screenEnabled;
    const startScreen = useMonitoring().startScreen;

    const [update, setUpdate] = useState(0);
    
    const json = useRest().json;
    const { value: contest } = useAsync(json, `contest`, []);
    const { value: tasks } = useAsync(json, `tasks`, [contestStarted]);
    const { value: init } = useAsync(json, `init`, [update]);


    const routeActive = (paths) => {
        paths = Array.isArray(paths) ? paths : [paths];
        return paths.some(p => location.pathname.indexOf(p) === 0)
    }

    /** map menu config to string to determine which element to render */
    const itemType = item => {
        if (item.heading) return 'heading';
        if (!item.submenu) return 'menu';
        if (item.submenu) return 'submenu';
    }

    if (!contest || !tasks || !init) return <PageLoader />

    return (
        <aside className='aside-container'>
            <div className="aside-inner">
                <nav data-sidebar-anyclick-close="" className="sidebar">
                    <ul className="sidebar-nav">
                        <Link to="/">
                            <SidebarLogo />
                        </Link>
                        <div style={{textAlign: 'center'}}>
                            {user.role==='USER' && user.check_internet && <TooltipItem id='1' placement="top" content="Интернет">
                                <span className={"circle circle-lg bg-success"} data-title="normal"></span>
                            </TooltipItem>}
                            {user.role==='USER' && user.check_camera && <TooltipItem id='2' placement="top" content="Камера">
                                <span className={"circle circle-lg " + (cameraEnabled?"bg-success":"bg-danger")} data-title="normal"></span>
                            </TooltipItem>}
                            {user.role==='USER' && user.check_screen && <TooltipItem id='3' placement="top" content="Екран">
                                <span className={"circle circle-lg " + (screenEnabled?"bg-success":"bg-danger")} data-title="normal"></span>
                            </TooltipItem>}
                        </div>
                        <div style={{textAlign: 'center', cursor: 'pointer'}}>
                            {user.role==='USER' && user.check_internet && <span style={{marginLeft: "5px", marginRight: "5px"}}><em className="fas fa-sitemap"></em></span>}
                            {user.role==='USER' && user.check_camera && <span onClick={startCamera} style={{marginLeft: "5px", marginRight: "5px"}}><em className="fas fa-camera"></em></span>}
                            {user.role==='USER' && user.check_screen && <span onClick={startScreen} style={{marginLeft: "5px", marginRight: "5px"}}><em className="fas fa-desktop"></em></span>}
                        </div>

                        {contest.type !== 'dzi' && contest.type !== 'nvo' && startTime && endTime && 
                        <ContestCountdown startTime={startTime} endTime={endTime} />}

                        {contest.type === 'nvo' &&
                        <DZIContestCountdown update={update} setUpdate={setUpdate} />}

                        <SidebarItemHeader item={Menu.items.menu} />
                        <SidebarItem isActive={routeActive(Menu.items.home.path)} item={Menu.items.home} />
                        {/* <SidebarItem isActive={routeActive(Menu.items.monitoring.path)} item={Menu.items.monitoring} /> */}
                        {/* <SidebarItem isActive={routeActive(Menu.items.rules.path)} item={Menu.items.rules} /> */}
                        <SidebarItem isActive={routeActive(Menu.items.communication.path)}
                        item={{...Menu.items.communication, label: { value: unread, color: 'danger' }}} />
                        {contest.type === 'icpc' && <SidebarItem isActive={routeActive(Menu.items.standing.path)} item={Menu.items.standing} />}
                        {!contestStarted && <SidebarItemHeader item={Menu.items.tasksWaiting} />}
                        {contestStarted && <SidebarItemHeader item={Menu.items.tasks} />}

                        {tasks.length && contest.type === 'nvo' && tasks.filter((t, i) => i+1 === init.started_tasks).map((t, i) => {
                             const item = {name: t.name, icon: 'fas fa-puzzle-piece', path: t.is_quiz?`/task/${t.number}/quiz/1`:`/task/${t.number}`};
                             return <SidebarItem isActive={routeActive(item.path)} item={item} key={i} />
                         })}

                        {tasks.length && contest.type !== 'nvo' && tasks.map((t, i) => {
                            const item = {name: t.name, icon: 'fas fa-puzzle-piece', path: t.is_quiz?`/task/${t.number}/quiz/1`:`/task/${t.number}`};
                            return <SidebarItem isActive={routeActive(item.path)} item={item} key={i} />
                        })}

                        <SidebarItemHeader item={Menu.items.documentation} />
                        <li>
                            <Link target="_blank" to="/docs/en/index.html">
                                <em className="fas fa-book"></em><span>{t('sidebar.cpp_docu')}</span>
                            </Link>
                        </li>
                        {contest.type === 'ioi' && <SidebarItem isActive={routeActive(Menu.items.systemInfo.path)} item={Menu.items.systemInfo} />}
                    </ul>
                </nav>
            </div>
        </aside>
    );
    
}

const mapStateToProps = state => ({ settings: state.settings })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(withRouter(UserSidebar)));
