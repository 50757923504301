import React, {useState, useEffect, useRef} from 'react'
import Countdown from 'react-countdown';
import { useTranslation, withTranslation } from 'react-i18next';
import { useRest } from '../../RestContext';
import useAsync from '../../useAsync';
import PageLoader from '../Common/PageLoader';
import { now } from 'moment';
import { Button } from 'reactstrap';
import { useContest } from '../../ContestContext';
import { useHistory, withRouter } from 'react-router';
import swal from 'sweetalert';

const DZIContestCountdown = ({update, setUpdate}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const json = useRest().json;
    const post = useRest().post;
    const contestStarted = useContest().contestStarted;
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const { value: init, loading } = useAsync(json, `init`, [update]);
    
    useEffect(() => {
        if (!init) return;
        if (loading) return;
        if (!init.userTimeTillEnd) return;
        if (init.userTimeTillEnd<=0) return;

        const currentTime = now();
        setStartTime(currentTime + init.userTimeTillStart);
        setEndTime(currentTime + init.userTimeTillEnd);
    }, [loading]);

    const stop = async () => {
        if (now() - startTime <= 15*60*1000) {
            swal({
                title: 'Приключване на модул ' + init.started_tasks,
                text: 'Трябва да са минали поне 15 минути от началото, за да приключите.',
                icon: "warning",
                dangerMode: true,
            });
            return;
        }

        swal({
            title: 'Приключване на модул ' + init.started_tasks,
            text: 'Сигурни ли сте, че искате да приключите модула? След това няма да може да изпращате решения.',
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willFinish) => {
            if (willFinish) {
                await post(`users/stop`);
                setUpdate(u => u+1);
            }
        });
    }

    const start = async () => {
        swal({
            title: 'Започване на модул ' + (init.started_tasks+1),
            text: 'Сигурни ли сте, че искате да започнете модула?',
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willFinish) => {
            if (willFinish) {
                await post(`users/start`);
                history.push('/');
                setUpdate(u => u+1);
            }
        });


    }

    if (!contestStarted) return <div></div>

    if (!init) return <PageLoader />

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    const runningRenderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return init.tasks_left?<Button color='danger' onClick={start}>Започни модул {init.started_tasks+1}</Button>:<div></div>;
        }
        if (days > 0) return <span>{days}д {zeroPad(hours, 2)}:{zeroPad(minutes, 2)}:{zeroPad(seconds, 2)}</span>;
        return <span>{zeroPad(hours, 2)}:{zeroPad(minutes, 2)}:{zeroPad(seconds, 2)}
        <br/><Button color='info' onClick={stop}>Приключи модул {init.started_tasks}</Button></span>;
    };

    return (
        <div>
        {init.userTimeTillEnd && init.userTimeTillEnd > 0 && endTime &&
        <div>
            <div key={endTime} style={{ color: '#b8c7ce', textAlign: 'center', fontSize: '30px' }}>
                <Countdown date={endTime} renderer={runningRenderer} onComplete={()=>setUpdate(u => u+1)} />
            </div>     
        </div>
        }
        {(!init.userTimeTillEnd || init.userTimeTillEnd <= 0 || !endTime) && init.tasks_left > 0 &&
        <div style={{ color: '#b8c7ce', textAlign: 'center', fontSize: '30px' }}>
            <Button color='danger' onClick={start}>Започни модул {init.started_tasks+1}</Button>
        </div>
        }
        </div>
    )
}

export default withTranslation()(withRouter(DZIContestCountdown))
