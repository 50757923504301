import React from 'react';

import Header from '../Header'
import UserSidebar from './UserSidebar'
import Footer from '../Footer'
import { ContestProvider } from '../../../ContestContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MonitoringProvider } from '../../../MonitoringContext';

const UserBase = props => (
    <ContestProvider>
        <MonitoringProvider>
            <ToastContainer />
            <div className="wrapper">
                <Header />

                <UserSidebar />

                <section className="section-container">
                    { props.children }
                </section>

                <Footer />
            </div>
        </MonitoringProvider>
    </ContestProvider>
)

export default UserBase;
