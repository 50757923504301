import React, { useState } from 'react';
import { sendRequestWithToken, sendPostRequestWithToken, sendPostRequest } from './rest'
import swal from 'sweetalert';
import { useEffect } from 'react';
import PageLoader from './components/Common/PageLoader';
import useInterval from './useInterval';
import { useTranslation } from 'react-i18next';
import { config } from './Constants'

function request_image(url) {
    return new Promise(function(resolve, reject) {
        var img = new Image();
        img.onload = function() { resolve(img); };
        img.onerror = function() { reject(url); };
        img.src = url + '?random-no-cache=' + Math.floor((1 + Math.random()) * 0x10000).toString(16);
        setTimeout(function() { reject(Error('Timeout')); }, 2000);
    });
}

function ping(url, multiplier) {
    return new Promise(function(resolve, reject) {
        var start = (new Date()).getTime();
        var response = function(e) { 
            console.log(e);
            var delta = ((new Date()).getTime() - start);
            delta *= (multiplier || 1);
            resolve(delta); 
        };
        request_image(url).then(response).catch(response);
        
        // Set a timeout for max-pings, 5s.
        //setTimeout(function() { reject(Error('Timeout')); }, 5000);
    });
}

async function hasInternet() {
    try {
        await request_image("https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png");
        return true;
    } catch(e) {
    }
    try {
        await request_image("https://www.facebook.com/rsrc.php/v3/yg/r/KnYDHb9XAAp.png");
        return true;
    } catch(e) {
    }

    return false;
}

const AuthContext = React.createContext()

const AuthProvider = ({children}) => {

    const { t, ready: translationReady} = useTranslation();

    const [user, setUser] = useState({});
    const [ready, setReady] = useState(false);

    useInterval(
        async () => {
            if(user.username && user.role === 'USER' && user.check_internet) {
                const has = await hasInternet();
                if (has) {
                    const formData = new FormData();
                    formData.append('operation', 'INTERNET');
                    formData.append('message', '');
                    const token = btoa(unescape(encodeURIComponent(`${user.username}:${user.password}`)));
                    try {
                        await sendPostRequestWithToken(`${user.role.toLowerCase()}/log`, formData, token);
                    } catch(e) {
                    }
                    logout();
               
                    swal(t('login.error'), t('login.internet_access_error'), "error");
                }
            }
        }, 20000);

    useEffect(() => {
        async function fetchData() {
            const username = localStorage.getItem("username");
            const password = localStorage.getItem("password");
            if (username && password) {
                try {
                    await login(atob(decodeURIComponent(username)), atob(decodeURIComponent(password)), false);
                } catch(e) {
                    logout();
                }
            }
            setReady(true);
        }

        fetchData();
    }, []);

    if (!translationReady || !ready) return <PageLoader />

    function logout() {
        fetch(`${config.REST_URL.replace("/api", "")}/logout`)
        localStorage.removeItem("username");
        localStorage.removeItem("password");
        setUser({});
    }

    async function login(username, password, isNew) {
        if (!username) {
            swal(t('login.error'), t('login.enter_username'), "error");
            logout();
            return;
        }
        if (!password) {
            swal(t('login.error'), t('login.enter_password'), "error");
            logout();
            return;
        }

        if (/^[\u0400-\u04FF ]+$/.test(username)) {
            swal(t('login.error'), 'Въвели сте символи на кирилица в името!', "error");
            logout();
            return;
        }

        if (/^[\u0400-\u04FF ]+$/.test(password)) {
            swal(t('login.error'), 'Въвели сте символи на кирилица в паролата!', "error");
            logout();
            return;
        }

        var token = btoa(unescape(encodeURIComponent(`${username}:${password}`)));

        if (username.includes("@")) {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('password', password);
            const monResponse = await sendPostRequest('me/mon', formData);
            if (!monResponse.ok) {
                logout();
                swal(t('login.error'), t('login.wrong_credentials'), "error");
                return;
            }
            const monJson = await monResponse.json();
            token = monJson.token;
        }

        const response = await sendRequestWithToken('me', 'json', token);

        if (!response.ok) {
            logout();
            swal(t('login.error'), t('login.wrong_credentials'), "error");
            return;
        }

        const user = await response.json();
        
        if (isNew && user.role === 'USER' && user.check_internet) {
            const has = await hasInternet();
            if (has) {
                const formData = new FormData();
                formData.append('operation', 'INTERNET');
                formData.append('message', '');
                try {
                    await sendPostRequestWithToken(`${user.role.toLowerCase()}/log`, formData, token);
                } catch(e) {
                }
                logout();
                swal(t('login.error'), t('login.internet_access_error'), "error");
                return;
            }
        }

        user.username = user.name;
        //user.password = Buffer.from(token, 'base64').toString().split(":")[1];
        user.userrole = user.role.toLowerCase();
        user.token = token;
        setUser(user);
        localStorage.setItem("username", btoa(user.name));
        localStorage.setItem("password", btoa(user.password));
    }

    return (
        <AuthContext.Provider
            value={{
                login: login,
                logout: logout,
                user: user,
                username: user.username,
                usertoken: user.token,
                userrole: user.userrole,
                password: user.password
            }}>
            {children}
        </AuthContext.Provider>
    )

}

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth }
