import React from 'react';
import { withNamespaces, Trans, withTranslation } from 'react-i18next';
import { Link, withRouter, useLocation } from 'react-router-dom';
import { Collapse, Badge } from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../store/actions/actions';

import SidebarRun from '../Sidebar.run';

import Menu from './Menu.js';
import SidebarLogo from '../SidebarLogo';
import { useAuth } from '../../../AuthContext';
import ContestCountdown from '../ContestCountdown';
import { useContest } from '../../../ContestContext';
import { useRest } from '../../../RestContext';
import useAsync from '../../../useAsync';
import PageLoader from '../../Common/PageLoader';

/** Component to display headings on sidebar */
const SidebarItemHeader = ({item}) => (
    <li className="nav-heading">
        <span><Trans i18nKey={item.translate}>{item.heading}</Trans></span>
    </li>
)

/** Normal items for the sidebar */
const SidebarItem = ({item, isActive}) => (
    <li className={ isActive ? 'active' : '' }>
        <Link to={item.path} title={item.name}>
            {item.label && !!item.label.value && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
            {item.icon && <em className={item.icon}></em>}
            <span><Trans i18nKey={item.translate}>{item.name}</Trans></span>
        </Link>
    </li>
)

/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubItem = ({item, isActive, handler, children, isOpen}) => (
    <li className={ isActive ? 'active' : '' }>
        <div className="nav-item" onClick={ handler }>
            {item.label && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
            {item.icon && <em className={item.icon}></em>}
            <span><Trans i18nKey={item.translate}>{item.name}</Trans></span>
        </div>
        <Collapse isOpen={ isOpen }>
            <ul id={item.path} className="sidebar-nav sidebar-subnav">
                { children }
            </ul>
        </Collapse>
    </li>
)

/** Component used to display a header on menu when using collapsed/hover mode */
const SidebarSubHeader = ({item}) => (
    <li className="sidebar-subnav-header">{item.name}</li>
)

const AuthorSidebar = () => {
    const location = useLocation();
    const userrole = useAuth().user.userrole;
    
    const unread = useContest().unreadAnnouncements + useContest().unreadQuestions;
    const endTime = useContest().endTime;

    const json = useRest().json;
    // const { value: tasks } = useAsync(json, `tasks`, []);

    const routeActive = (paths) => {
        paths = Array.isArray(paths) ? paths : [paths];
        return paths.some(p => location.pathname.indexOf(p) === 0)
    }

    /** map menu config to string to determine which element to render */
    const itemType = item => {
        if (item.heading) return 'heading';
        if (!item.submenu) return 'menu';
        if (item.submenu) return 'submenu';
    }

    // if (!tasks) return <PageLoader />

    return (
        <aside className='aside-container'>
            <div className="aside-inner">
                <nav data-sidebar-anyclick-close="" className="sidebar">
                    <ul className="sidebar-nav">
                        <Link to="/">
                            <SidebarLogo />
                        </Link>

                        <SidebarItemHeader item={Menu.items.menu} />
                        <SidebarItem isActive={routeActive(Menu.items.tasks.path)} item={Menu.items.tasks} />
                        {/* <SidebarItem isActive={routeActive(Menu.items.submissions.path)} item={Menu.items.submissions} />
                        <SidebarItem isActive={routeActive(Menu.items.standing.path)} item={Menu.items.standing} /> */}
                    </ul>
                </nav>
            </div>
        </aside>
    );
    
}

const mapStateToProps = state => ({ settings: state.settings })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(withRouter(AuthorSidebar)));
