const Items = {
    menu: {heading: 'Меню'},
    home: {name: 'Начало', icon: 'fas fa-home', path: '/home'},
    contests: {name: 'Състезания', icon: 'fas fa-trophy', path: '/contests'},
    tasks: {name: 'Задачи', icon: 'fas fa-tasks', path: '/tasks'},
    submissions: {name: 'Решения', icon: 'fas fa-tasks', path: '/submissions'},
    communication: {name: 'Комуникация', icon: 'fas fa-envelope', path: '/communication'},
    documentation: {heading: 'Документация'},
    cpp: {name: 'C++ Документация', icon: 'fas fa-book', path: '/documentation'},
    standing: {name: 'Класиране', icon: 'fas fa-book', path: '/standing/A'}
}

const Menu = {items: Items};

export default Menu;
