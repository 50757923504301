import React, { Component } from 'react';

class SidebarLogo extends Component {

    render() {
        return (
            <div style={{textAlign: 'center'}}>
                {/* <div className="item user-block"> */}
                    <div className="user-block-picture">
                        <div className="user-block-status">
                            <img src={"img/"+process.env.REACT_APP_IMG_CONTEST_PREFIX+".png"} alt="Avatar" width="120" height="120" />
                        </div>
                    </div>
                {/* </div> */}
            </div>
        )
    }
}

export default SidebarLogo;
