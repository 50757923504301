import 'core-js/es6/string';
import 'core-js/es6/array';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/object';
import 'core-js/es6/promise';
import 'core-js/es7/object';
import 'core-js/es7/array';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';

import './i18n';

import configureStore from './store/store';
import { AuthProvider } from './AuthContext';
import { RestProvider } from './RestContext';
import { Notifications } from 'react-push-notification';
import { ContestProvider } from './ContestContext';
import { Suspense } from 'react';
import PageLoader from './components/Common/PageLoader';

const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <AuthProvider>
            <RestProvider>
                {/* <Notifications /> */}
                <App />
            </RestProvider>
        </AuthProvider>
    </Provider>,
    document.getElementById('app')
);
