const AdminMenu = [
    {
        name: 'Групи',
        icon: 'fas fa-users',
        path: '/groups'
    },
    {
        name: 'Задачи',
        icon: 'icon-doc',
        path: '/tasks'
    },
    {
        name: 'Решения',
        icon: 'far fa-file-code',
        path: '/submissions'
    },
    {
        name: 'Потребители',
        icon: 'fas fa-user',
        path: '/users'
    },
    {
        name: 'Групи за достъп',
        icon: 'fas fa-user-shield',
        path: '/permissiongroups'
    },
    {
        name: 'Състезания',
        icon: 'fas fa-code',
        path: '/competitions'
    }
];

const TeacherMenu = [
    {
        name: 'Групи',
        icon: 'fas fa-users',
        path: '/groups'
    },
    {
        name: 'Задачи',
        icon: 'icon-doc',
        path: '/tasks'
    },
    {
        name: 'Решения',
        icon: 'far fa-file-code',
        path: '/submissions'
    },
    {
        name: 'Групи за достъп',
        icon: 'fas fa-user-shield',
        path: '/permissiongroups'
    },
    {
        name: 'Състезания',
        icon: 'fas fa-code',
        path: '/competitions'
    }
];

const Items = {
    menu: {heading: 'Menu', translate: 'sidebar.menu'},
    home: {name: 'Home', icon: 'fas fa-home', path: '/home', translate: 'sidebar.dashboard'},
    certificates: {name: 'Certificates', icon: 'fas fa-home', path: '/certificates', translate: 'sidebar.certificates'},
    check: {name: 'Check', icon: 'fas fa-home', path: '/check', translate: 'sidebar.check'},
    communication: {name: 'Communication', icon: 'fas fa-envelope', path: '/communication', translate: 'sidebar.communication'},
    tasks: {heading: 'tasks', translate: 'sidebar.tasks'},
    contests: {heading: 'contests', translate: 'sidebar.contests'},
    tasksWaiting: {heading: 'Tasks (coming soon)', translate: 'sidebar.tasks_coming_soon'},
    documentation: {heading: 'Documentation', translate: 'sidebar.docu'},
    systemInfo: {name: 'Scoring', icon: 'fas fa-book', path: '/system', translate: 'sidebar.scoring'},
    cpp: {name: 'C++ Documentation', icon: 'fas fa-book', path: '/documentation'},
    standing: {name: 'Класиране', icon: 'fas fa-book', path: '/standing/D'}
}

const UserMenu = [
    {
        heading: 'Меню'
    },
    {
        name: 'Начало',
        icon: 'fas fa-home',
        path: '/home'
    },
    {
        name: 'Комуникация',
        icon: 'fas fa-envelope',
        path: '/communication',
        label: { value: '3', color: 'danger' },
        //fas fa-question
    },
    {
        heading: 'Задачи'
    },
    {
        name: 'bills',
        icon: 'fas fa-puzzle-piece',
        path: '/task/1'
    },//medapps, pencil-alt
    {
        name: 'sequence',
        icon: 'fas fa-puzzle-piece',
        path: '/task/2'
    },
    {
        name: 'diff',
        icon: 'fas fa-puzzle-piece',
        path: '/task/3'
    },
    {
        heading: 'Документация'
    },
    {
        name: 'C++ Документация',
        icon: 'fas fa-book',
        path: '/documentation'
        //readme, clipboard, paperclip
    }
];

const Menu = {user: UserMenu, items: Items};

export default Menu;
