import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Link, withRouter, useLocation } from 'react-router-dom';
import { Badge } from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../store/actions/actions';

import Menu from './Menu.js';
import SidebarLogo from '../SidebarLogo';
import { useRest } from '../../../RestContext';
import useAsync from '../../../useAsync';
import PageLoader from '../../Common/PageLoader';

/** Component to display headings on sidebar */
const SidebarItemHeader = ({item}) => (
    <li className="nav-heading">
        <span><Trans i18nKey={item.translate}>{item.heading}</Trans></span>
    </li>
)

/** Normal items for the sidebar */
const SidebarItem = ({item, isActive}) => {
    const post = useRest().post;
    const change = async () => {
        if (item.name === Menu.items.communication.name) {
            await post(`communication/seen`, new FormData());
        }
    }
    return (
        <li className={ isActive ? 'active' : '' }>
            <Link to={item.path} title={item.name} onClick={() => change()}>
                {item.label && !!item.label.value && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
                {item.icon && <em className={item.icon}></em>}
                <span><Trans i18nKey={item.translate}>{item.name}</Trans></span>
            </Link>
        </li>
    )
}

const ScorerSidebar = () => {
    const location = useLocation();
    
    const json = useRest().json;
    const { value: tasks } = useAsync(json, `tasks`, []);

    const routeActive = (paths) => {
        paths = Array.isArray(paths) ? paths : [paths];
        return paths.some(p => location.pathname.indexOf(p) === 0)
    }

    if (!tasks) return <PageLoader />

    return (
        <aside className='aside-container'>
            <div className="aside-inner">
                <nav data-sidebar-anyclick-close="" className="sidebar">
                    <ul className="sidebar-nav">
                        <Link to="/">
                            <SidebarLogo />
                        </Link>
                        {/* {startTime && endTime && 
                        <ContestCountdown startTime={startTime} endTime={endTime} />} */}

                        <SidebarItemHeader item={Menu.items.tasks} />
                        {tasks.map((t, i) => {
                            const item = {name: t.name, icon: 'fas fa-puzzle-piece', path: `/task/${t.id}`};
                            return <SidebarItem isActive={routeActive(item.path)} item={item} key={i} />
                        })}

                    </ul>
                </nav>
            </div>
        </aside>
    );
    
}

const mapStateToProps = state => ({ settings: state.settings })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(withRouter(ScorerSidebar)));
