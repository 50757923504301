import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import { useRest } from '../../RestContext';
import useAsync from '../../useAsync';
import PageLoader from '../Common/PageLoader';
import swal from 'sweetalert';
import { useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

const Header = () => {

    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        localStorage.setItem('lang', lng); 
        i18n.changeLanguage(lng);
    }

    const json = useRest().json;
    const post = useRest().post;

    const [change, setChange] = useState(0);

    const { value: user } = useAsync(json, `me`, [change]);
    const logout = useAuth().logout;

    function finish(e) {
        e.preventDefault();
        swal({
            title: t('header.finish_title'),
            text: t('header.finish_text'),
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willFinish) => {
            if (willFinish) {
                await post(`users/finish`);
                setChange(c => c+1);
            }
        });
    }

    function logout2(e) {
        e.preventDefault();

        if (user.role !== 'USER1' || user.finished) {
            logout();
            return;
        }

        swal({
            title: t('header.sign_out_title'),
            text: t('header.sign_out_text'),
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willLogout) => {
            if (willLogout) {
                logout();
            }
        });
    }

    if (!user) return <PageLoader />;

    return (
        <header className="topnavbar-wrapper">
            { /* START Top Navbar */ }
            <nav className="navbar topnavbar">
                { /* START navbar header */ }
                <div className="navbar-header">
                    <Link to="/" className="navbar-brand">
                        <div style={{color: 'white'}}>
                            {t(`contests.${process.env.REACT_APP_CONTEST_NAME}.short_name`)}
                        </div>
                    </Link>
                </div>
                { /* END navbar header */ }

                { /* START Left navbar */ }
                <ul className="navbar-nav mr-auto flex-row">
                        
                    <li className="nav-item d-none d-md-block">
                    <div className="nav-link" style={{fontSize:'20px', color: 'white', paddingLeft:'20px'}}>
                    {user.contest && `${user.name} - ${user.display_name}, ${user.contest} ${t('header.group')}`}
                    {!user.contest && `${user.name} - ${user.display_name}`}
                    </div>
                    </li>
                    <li className="nav-item">
                        { /* Button used to collapse the left sidebar. Only visible on tablet and desktops */ }
                        { /*<a href="" className="nav-link d-none d-md-block d-lg-block d-xl-block" onClick={ this.toggleCollapsed }>
                            <em className="fas fa-bars"></em>
                        </a>*/ }
                        { /* Button to show/hide the sidebar on mobile. Visible on mobile only. */ }
                        { /*<a href=""  className="nav-link sidebar-toggle d-md-none" onClick={ this.toggleAside }>
                            <em className="fas fa-bars"></em>
                        </a> */ }
                    </li>
                    { /* START User avatar toggle */ }
                    {/*<li className="nav-item d-none d-md-block">
                        <a  className="nav-link" onClick={ this.toggleUserblock }>
                            <em className="icon-user"></em>
                        </a>
                        </li>*/}
                    { /* END User avatar toggle */ }
                    { /* START lock screen */ }
                    { /*<li className="nav-item d-none d-md-block">
                        <Link to="lock" title="Lock screen" className="nav-link">
                            <em className="icon-lock"></em>
                        </Link>
                    </li>*/ }
                    { /* END lock screen */ }
                </ul>
                { /* END Left navbar */ }
                { /* START Right Navbar */ }

                {/* {user.role === 'USER'  && 
                <button onClick={() => {localStorage. setItem('lang', 'bg'); changeLanguage('bg')}}>bg</button>
                }
                {user.role === 'USER'  && 
                <button onClick={() => {localStorage. setItem('lang', 'en'); changeLanguage('en')}}>en</button>
                } */}

                {user.role === 'USER' && process.env.REACT_APP_ALLOW_TRANSLATION === 'true' &&
                <ul className="navbar-nav flex-row" style={{padding: '10px'}}>
                    <li className="nav-item">
                        <select onChange={(e) => changeLanguage(e.target.value)} value={t('lang')} className="form-control">
                            <option value="en">English</option>
                            <option value="bg">Български</option>
                        </select>
                    </li>
                </ul>
                }

                {/* {user.role === 'USER' && user.finished &&
                    <ul className="navbar-nav flex-row">
                        <li className="nav-item">
                            <div className="text-white" style={{fontSize: '1.5em'}}>{t('header.finished')}</div>
                        </li>
                    </ul>
                }
                {user.role === 'USER' && !user.finished &&
                <ul className="navbar-nav flex-row">
                    <li className="nav-item">
                        <Link onClick={finish} to="/" className="nav-link">
                            <em className="fas fa-power-off"> {t('header.finish')}</em>
                        </Link>
                    </li>
                </ul>
                } */}
                <ul className="navbar-nav flex-row">
                    <li className="nav-item">
                        <Link onClick={logout2} to="/login" className="nav-link">
                            <em className="fas fa-sign-out-alt"> {t('header.sign_out')}</em>
                        </Link>
                    </li>
                </ul>
                { /* END Right Navbar */ }

                { /* START Search form */ }
                <form className="navbar-form" role="search" action="search.html">
                    <div className="form-group">
                        <input className="form-control" type="text" placeholder="Type and hit enter ..."/>
                        <div className="fa fa-times navbar-form-close" data-search-dismiss=""></div>
                    </div>
                    <button className="d-none" type="submit">Submit</button>
                </form>
                { /* END Search form */ }
            </nav>
            { /* END Top Navbar */ }
        </header>
        );
}

export default withTranslation()(Header);
