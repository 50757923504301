import { config } from './Constants'

export async function sendJsonRequest(url) {
    const response = await fetch(`${config.REST_URL}/${url}`, {
        responseType: 'json'
    });
    return await response.json();
}

export function sendRequestWithToken(url, type, token) {
    return fetch(`${config.REST_URL}/${url}`, {
        headers: {
            'Authorization': `Basic ${token}`
        },
        responseType: type
    });
}

export function sendPostRequestWithToken(url, formData, token) {
    return fetch(`${config.REST_URL}/${url}`, {
        method: 'POST',
        headers: {
            'Authorization': `Basic ${token}`
        },
        body: formData,
        responseType: 'json'
    });
}

export function sendPostRequest(url, formData) {
    return fetch(`${config.REST_URL}/${url}`, {
        method: 'POST',
        headers: {
            'Authorization': ``
        },
        body: formData,
        responseType: 'json'
    });
}
