import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();

    const year = new Date().getFullYear();
    return (
        <footer className="footer-container">
            <span>&copy; 2016-{year} - {t('footer.made_with')} <span style={{color: 'rgb(240, 80, 80)'}}>❤</span> {t('footer.for_bulgarian_informatics')} в <a target="_blank" href="https://olimpiici.com">школа "Олимпийци"</a>.</span>
        </footer>
    );
}

export default withTranslation()(Footer);
