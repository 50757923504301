import React, { useState } from 'react';
import { useEffect } from 'react';
import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';
import { useAuth } from './AuthContext';
import { config } from './Constants';
import { now } from 'moment';
import { toast } from 'react-toastify';
import { useRest } from './RestContext';
import useAsync from './useAsync';
import PageLoader from './components/Common/PageLoader';

const ContestContext = React.createContext()

const ContestProvider = ({children}) => {

    const username = useAuth().username;
    const password = useAuth().password;
    const user = useAuth().user;
    const [connect, setConnect] = useState(0);

    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [timeToNextSubmit, setTimeToNextSubmit] = useState();
    const [contestStarted, setContestStarted] = useState(false);
    const [contestFinished, setContestFinished] = useState(false);
    
    const [unreadAnnouncements, setUnreadAnnouncements] = useState(0);
    const [unreadQuestions, setUnreadQuestions] = useState(0);
    const [updateAnnouncements, setUpdateAnnouncements] = useState(0);
    const [updateQuestions, setUpdateQuestions] = useState(0);

    const [submissionsLeft, setSubmissionsLeft] = useState();
    const [updateSubmissions, setUpdateSubmissions] = useState(0);

    const json = useRest().json;
    const { value: init } = useAsync(json, `init`, []);
    
    // const [stompClient, setStompClient] = useState();

    useEffect(() => {
        if (!startTime || contestStarted) return;
    
        setTimeout(function() {
            setContestStarted(true);
        },
        Math.max(startTime-now(), 0));
    }, [startTime]);

    useEffect(() => {
        if (!endTime) return;
        
        if (endTime <= now() && !contestFinished) {
            setContestFinished(true);
        } else if (!contestFinished) {
            setTimeout(function() {
                setContestFinished(true);
            },
            Math.max(endTime-now(), 0));
        }
    }, [endTime]);

    useEffect(() => {
        if (unreadQuestions !== 0) setUpdateQuestions(n => n+1);
    }, [unreadQuestions]);

    useEffect(() => {
        if (unreadAnnouncements !== 0) setUpdateAnnouncements(n => n+1);
    }, [unreadAnnouncements]);

    useEffect(() => {
        if (user.role !== 'USER' && user.role !== 'COORDINATOR' && user.role !== 'ADMIN' && user.role !== 'AUTHOR') return;
        if (!init) return;

        setStartTime(now() + parseInt(init.timeTillStart)+200);
        setEndTime(now() + parseInt(init.timeTillEnd));

        // if (1 === 1) return;

        const sock = new SockJS(config.WS_URL);
        const stompClient = Stomp.over(sock);

        function showMessage(title, message, type) {
            toast(<div style={{wordBreak: 'break-word'}}>{title}<br />{message}</div>, {
                type: 'error',
                position: 'top-right'
            });
        }
        
        stompClient.connect(username, password,
            function() {
                //setStompClient(stompClient);
                if (user.role === 'USER' || user.role === 'COORDINATOR') {
                    stompClient.subscribe(`/topic/announcements/${user.contest}`, function (msg) {
                        const data = JSON.parse(msg.body);
                        setUnreadAnnouncements(u => u+1);
                        showMessage(data.topic, data.message, 'warning');
                    });
                    stompClient.subscribe(`/user/queue/announcements`, function (msg) {
                        const data = JSON.parse(msg.body);
                        if (data.action==='set') setUnreadAnnouncements(data.value);
                    });
                    stompClient.subscribe('/user/queue/questions', function (msg) {
                        const data = JSON.parse(msg.body);
                        if (data.action==='add') {
                            setUnreadQuestions(u => u + data.value);
                            showMessage(data.topic, data.message, 'info');
                        }
                        if (data.action==='set') setUnreadQuestions(data.value);
                        if (data.action==='update') setUpdateQuestions(n => n+1);
                    });
                    stompClient.subscribe('/user/queue/time', function (msg) {
                        const data = JSON.parse(msg.body);
                        if (data.timeTillStart) setStartTime(now() + parseInt(data.timeTillStart)+200);
                        if (data.timeTillEnd) setEndTime(now() + parseInt(data.timeTillEnd));
                        if (data.timeToNextSubmit) setTimeToNextSubmit(now() +  parseInt(data.timeToNextSubmit));
                    });
                }
                stompClient.subscribe('/user/queue/submissions', function (msg) {
                    const data = JSON.parse(msg.body);
                    if (data.action==='add') {
                        setSubmissionsLeft(s => s + data.value);
                        setUpdateSubmissions(s => s+1);
                    }
                    if (data.action==='set') {
                        setSubmissionsLeft(data.value);
                        setUpdateSubmissions(s => s+1);
                    }
                    if (data.action==='update') {
                        setUpdateSubmissions(s => s+1);
                    }
                });
                if (user.role === 'USER') stompClient.send("/app/connect", {}, "hello");
            },
            function stompFailure(error) {
                console.log(error);
                setTimeout(() => setConnect(c => c+1), 5000);
            }
        );

        return () => {
            if (stompClient && stompClient.connected) stompClient.disconnect();
        }
    }, [init]);

    if (!init) return <PageLoader />;

    return (
        <ContestContext.Provider
            value={{
                startTime,
                endTime,
                contestStarted,
                contestFinished,
                timeToNextSubmit,
                unreadAnnouncements,
                unreadQuestions,
                updateQuestions,
                updateAnnouncements,
                submissionsLeft,
                updateSubmissions
            }}>
            {children}
        </ContestContext.Provider>
    )

}

const useContest = () => React.useContext(ContestContext);

export { ContestProvider, useContest }
