import React from 'react';

import Header from '../Header'
import AuthorSidebar from './AuthorSidebar'
import Footer from '../Footer'
import { ContestProvider } from '../../../ContestContext';

const AuthorBase = props => (
    <ContestProvider>
        <div className="wrapper">
            <Header />

            <AuthorSidebar />

            <section className="section-container">
                { props.children }
            </section>

            <Footer />
        </div>
    </ContestProvider>
)

export default AuthorBase;
