import React, { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import swal from 'sweetalert';
import { useRest } from './RestContext';
import useInterval from './useInterval';
import { useTranslation } from 'react-i18next';
import { useAuth } from './AuthContext';

const MonitoringContext = React.createContext()

const MonitoringProvider = ({children}) => {

    const { t } = useTranslation();
    const user = useAuth().user;
    const post = useRest().post;

    const [update, setUpdate] = useState(0);

    const cameraVideoRef = useRef();
    const cameraCanvasRef = useRef();

    const screenVideoRef = useRef();
    const screenCanvasRef = useRef();

    const [cameraEnabled, setCameraEnabled] = useState(false);
    const [screenEnabled, setScreenEnabled] = useState(false);

    const constraints = {
        video: {
            cursor: "always"
            
        },
        audio: false
        // deviceId: "screen:0:0",
    };

    function startCamera() {
        if (cameraEnabled) {
            swal({
                title: t('snapshots.stop_camera_title'),
                text: t('snapshots.stop_camera_message'),
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(async (agree) => {
                if (!agree) return;

                cameraVideoRef.current.srcObject.getVideoTracks().forEach(function(track) {
                    track.stop();
                });
                setCameraEnabled(false);

                const formData = new FormData();
                formData.append('operation', "CAMERA_SHARE");
                formData.append('message', 'STOPPED');
                post('log', formData);
            });
        } else {
            navigator.mediaDevices.getUserMedia(constraints).then((cameraStream) => {
                cameraVideoRef.current.srcObject = cameraStream;
                cameraVideoRef.current.play();
                cameraStream.getVideoTracks()[0].onended = () => {
                    setCameraEnabled(false);
                }
                setCameraEnabled(true);

                const formData = new FormData();
                formData.append('operation', "CAMERA_SHARE");
                formData.append('message', cameraStream.getVideoTracks()[0].label);
                post('log', formData);
            }).catch(function(e) {
                const formData = new FormData();
                formData.append('operation', "CAMERA_SHARE");
                formData.append('message', e);
                post('log', formData);

                // startScreen();
            });
        }
    }

    function startScreen() {
        if (screenEnabled) {
            swal({
                title: t('snapshots.stop_screen_title'),
                text: t('snapshots.stop_screen_message'),
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(async (agree) => {
                if (!agree) return;

                screenVideoRef.current.srcObject.getVideoTracks().forEach(function(track) {
                    track.stop();
                });
                setScreenEnabled(false);
                
                const formData = new FormData();
                formData.append('operation', "SCREEN_SHARE");
                formData.append('message', 'STOPPED');
                post('log', formData);
            })
        } else {
            navigator.mediaDevices.getDisplayMedia(constraints).then((screenStream) => {
                screenVideoRef.current.srcObject = screenStream;
                screenVideoRef.current.play();
                screenStream.getVideoTracks()[0].onended = () => {
                    setScreenEnabled(false);
                    const formData = new FormData();
                    formData.append('operation', "SCREEN_SHARE");
                    formData.append('message', 'STOPPED');
                    post('log', formData);
                }
                setScreenEnabled(true);

                const label = screenStream.getVideoTracks()[0].label;
                const formData = new FormData();
                formData.append('operation', "SCREEN_SHARE");
                formData.append('message', label);
                post('log', formData);

                if (!label.includes('screen') && !label.includes('Monitor')) {
                    swal({
                        title: 'Споделяне на екран',
                        text: 'Трябва да споделите целия си екран!',
                        icon: "warning",
                        dangerMode: true,
                    })
                }
            }).catch(function(e) {
                const formData = new FormData();
                formData.append('operation', "SCREEN_SHARE");
                formData.append('message', e);
                post('log', formData);

                // startScreen();
            });
        }
    }

    useEffect(() => {
        if (user.role !== 'USER') return;
        if (!user.check_camera && !user.check_screen) return;
        const text = t('snapshots.participation_rules') + '\n' 
        // + (user.check_internet?`  - ${t('snapshots.check_internet')}\n`:'')
        + (user.check_camera?`  - ${t('snapshots.check_camera')}\n`:'')
        + (user.check_screen?`  - ${t('snapshots.check_screen')}\n`:'')

        swal({
            title: t('snapshots.participation_title'),
            text: text,
            icon: "warning",
            dangerMode: true,
        }).then(async (agree) => {
            // if (!agree) return;
            if (user.check_camera) startCamera();
            if (user.check_screen) startScreen();
        });

        return () => {
            cameraVideoRef.current.srcObject && cameraVideoRef.current.srcObject.getVideoTracks().forEach(function(track) {
                track.stop();
            });
            screenVideoRef.current.srcObject && screenVideoRef.current.srcObject.getVideoTracks().forEach(function(track) {
                track.stop();
            });
        }
    }, []);

    useEffect(() => {
        function send(videoRef, canvasRef, enabled, restUrl) {
            if (!enabled) return;
            
            var context = canvasRef.current.getContext('2d');

            const videoTrack = videoRef.current.srcObject.getVideoTracks()[0];
            const { height, width } = videoTrack.getSettings();

            if (width && height) {
                canvasRef.current.width = width;
                canvasRef.current.height = height;
                context.drawImage(videoRef.current, 0, 0, width, height);

                canvasRef.current.toBlob(async (blob) => {
                    const formData = new FormData();
                    formData.append('file', new Blob([blob]), "snapshot.png");
            
                    await post(restUrl, formData);
                });
            }
        }

        if (update%2 === 0) send(screenVideoRef, screenCanvasRef, screenEnabled, "snapshots/screen");
        else send(cameraVideoRef, cameraCanvasRef, cameraEnabled, "snapshots/camera");
    }, [update]);

    useInterval(() => {
        setUpdate(u => u+1);
    }, 15000);

    return (
        <MonitoringContext.Provider
            value={{
                cameraEnabled,
                setCameraEnabled,
                screenEnabled,
                setScreenEnabled,
                startScreen,
                startCamera,
                screenVideoRef,
                cameraVideoRef
            }}>
            {children}
            <div style={{display: 'none'}}>
                <video ref={screenVideoRef} />
                <video ref={cameraVideoRef} />

                <canvas ref={screenCanvasRef} />
                <canvas ref={cameraCanvasRef} />
            </div>
        </MonitoringContext.Provider>
    )
}

const useMonitoring = () => React.useContext(MonitoringContext);

export { MonitoringProvider, useMonitoring }
